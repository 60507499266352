import styled from "styled-components";
import colors from "../../styles/colors";
import metrics from "../../styles/metrics";

export const ProdutoWrapper = styled.section`
  padding: 120px 0;
  .title {
    display: grid;
    grid-template-columns: 1fr 0.4fr;
    grid-column-gap: 30px;

    h1 {
      color: #ffffff;
      font-size: 65px;

      &:before {
        background-color: #414141;
        width: 95%;
        margin-left: -10%;
        height: 150px;
        content: "";
        position: absolute;
        z-index: -1;
        top: -30px;
      }
    }
    h2 {
      z-index: 1;
      font-weight: ${metrics.regular};
      font-size: ${metrics.bigTextFontSize};
      text-transform: inherit;
      max-width: 70%;
    }
  }

  @media (max-width: 480px) {
    .title {
      padding-left: 20px;
      grid-template-columns: 1fr;
      grid-row-gap: 50px;
      h1 {
        font-size: ${metrics.bigTitleFontSize};
        padding-left: 0;
      }
    }
  }
`;
export const Container = styled.div`
  margin: 0 auto;
  max-width: 1280px;
  width: 95%;

  .mainWrapper {
    padding-top: 150px;
    display: grid;
    grid-template-columns: 0.9fr 1fr;
    grid-column-gap: 100px;
    .fotosWrapper {
      .fotoPrincipal {
        height: 550px;
        width: 100%;
        background-size: 60%;
        background-position: center;
        background-repeat: no-repeat;
      }
      .gallery {
        /* height: 130px; */
        width: 100%;
        display: grid;
        grid-template-columns: repeat(4, 1fr);

        &:hover {
          .item:not(:hover) {
            &:after {
              opacity: 1;
            }
          }
        }
        .item {
          background-size: cover;
          background-position: center;
          height: 130px;
          width: 100%;
          position: relative;

          &:after {
            position: absolute;
            content: "";
            width: 100%;
            height: 130px;
            transition: 0.5s ease;
            opacity: 0;
            background-color: rgba(0, 0, 0, 0.4);
          }
        }
      }
    }

    .infoWrapper {
      
      h1 {
        font-size: ${metrics.smallTitleFontSize};
        margin-bottom: 20px;
      }
      p {
        line-height: 24px;
        font-size: 15px;
        margin-bottom: 25px;
      }
      h3 {
        font-size: 30px;
        margin-bottom: 10px;
        text-transform: uppercase;
      }
      h4 {
        margin-bottom: 10px;
        font-size: 30px;
      }
      ul {
        li {
          list-style: none;
          padding-left: 20px;
          line-height: 22px;
          &:before {
            content: "✓";
            left: -20px;
            position: relative;
          }
        }
      }
    }
  }

  .cta {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    a {
      margin: 70px 50px;
    }
  }

  @media (max-width: 480px) {
    .mainWrapper {
      padding-top: 50px;
      grid-template-columns: 1fr;
      .infoWrapper {
        p {
          line-height: 30px;
        }
      }
      .fotosWrapper {
        margin-bottom: 50px;
        .fotoPrincipal {
          height: 250px;
        }
        .gallery {
          /* height: 100px; */
          .item {
            height: 100px;
            &:after {
              height: inherit;
            }
          }
        }
      }
    }
    .cta {
      flex-direction: column;
      a {
        margin: 10px auto;
      }
    }
  }
`;

export const DescricaoWrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  h2 {
    background-color: black;
    color: white;
    font-size: ${metrics.smallTitleFontSize};
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 100px;
    height: 100px;
    z-index: 5;
    position: relative;
  }
  .content {
    z-index: 1;
    position: relative;
    margin-top: -25px;
    border-top: 7px solid black;
    display: block;
    width: 100%;
    padding: 70px 5%;
    background-color: #f0f0f0;

    h3 {
      font-size: 30px;
      margin-bottom: 10px;
      text-transform: uppercase;
    }
    h4 {
      margin-bottom: 20px;
      font-size: 30px;
    }
    ul {
      li {
        list-style: none;
        padding-left: 20px;
        line-height: 22px;
        &:before {
          content: "✓";
          left: -20px;
          position: relative;
        }
      }
    }
    p {
      margin-bottom: 40px;
      font-size: 15px;
      line-height: 22px;
    }
  }
  @media (max-width: 480px) {
    h2 {
      padding: 0;
      width: 100%;
    }
  }
`;
