import React, { useState, useEffect } from "react";

// Importa o serviço da API
import api from "../../services/api";

import { PageTitle, SubmitButton } from "../../styles/global";
import { Container, Unform, UnformInput, Donwloads } from "./styles";

import ademail from "../../services/ademail";

import search from "../../assets/icons/search.svg";
import support from "../../assets/icons/support.svg";
import website from "../../assets/icons/website.svg";

import * as Yup from "yup";

export default function Suporte() {

  const [donwloads, setDonwloads] = useState([]);          // Estado inicial recebe um array vazio
  const [loading, setLoading] = useState(true);      // Seta o estado inicial do loading

  // Declara o useEffect para setar o loading
    useEffect(() => {
      setLoading(true);
  }, []);

  // Declara outro useEffect para os Donwloads
  useEffect(() => {
    // Faz a requisição e já atribui o retorno dela ao estado dos Donwloads
    async function getDonwloads() {
       // Requisição para API
       const { data } = await api.get('api/downloads');
       
       // Já chama a função para setar os dados no estado
       setDonwloads(data[0]);

       console.log( data[0] );
    };

    // Já chama a função para ficar dentro do useEffect
    getDonwloads();
  }, []);

  const [formState, setFormState] = useState("Enviar");

  const handleFormSubmit = async data => {
    setFormState("Enviando...");

    const body = `<p>Nome: ${data.Nome}</p>
    <p>Assunto: ${data.Assunto}</p>
    <p>Email: ${data.Email}</p>
    <p>Telefone: ${data.Telefone}</p>
    <p>Mensagem: ${data.Mensagem}</p>`;

    const mail = {
      to: "informacoes@gestpar.com.br",
      from: data.Email,
      as: data.Nome,
      subject: `Gestpar - Solicitação de Suporte`,
      message: body
    };

    try {
      const response = await ademail.post("/email", mail);
      if (response) {
        setFormState("Enviado!");
      }
    } catch (err) {
      setFormState("Erro!");
    }
  };

  const schema = Yup.object().shape({
    Nome: Yup.string().required("Campo Obrigatório"),
    Email: Yup.string()
      .email("Este não é um e-mail válido")
      .required("Campo Obrigatório"),
    Telefone: Yup.string("Campo Obrigatório"),
    Assunto: Yup.string().required("Campo Obrigatório"),
    Mensagem: Yup.string().required("Campo Obrigatório")
  });
  return (
    <>

    <Donwloads>
      <PageTitle>Suporte</PageTitle>

      <div className="grid">
        <div className="block">
          <img src={support} alt="Suporte" />
          <h2>
            Suporte<br/>Remoto
          </h2>
          <ul>
            {!!donwloads.suporte_remoto
            ? donwloads.suporte_remoto.map( (down, index) => (
              <li key={index}>
                <a href={down.link}>{down.titulo}</a>
              </li>
              ))
            : ""}
          </ul>
        </div>
        <div className="block">
          <img src={search} alt="Suporte" />
          <h2>Coleta de<br/>Contadores</h2>
          <ul>
            {!!donwloads.coleta_de_contadores
            ? donwloads.coleta_de_contadores.map( (down, index) => (
              <li key={index}>
                <a href={down.link}>{down.titulo}</a>
              </li>
              ))
            : ""}
          </ul>
        </div>
        <div className="block">
          <img src={website} alt="Suporte" />
          <h2>Softwares de<br/>Monitoramento</h2>
          <ul>
            {!!donwloads.softwares_de_monitoramento
            ? donwloads.softwares_de_monitoramento.map( (down, index) => (
              <li key={index}>
                <a href={down.link}>{down.titulo}</a>
              </li>
              ))
            : ""}
          </ul>
        </div>
      </div>
    </Donwloads>

    <Container>

      <Unform onSubmit={handleFormSubmit}>
        <div className="formHead">
          <div className="precisando">
            <h1>Quer falar diretamente com a gente?</h1>
            <h3>Entre em contato através:</h3>

            <div>
              <p>(42) 3026-2222</p>
              <p>suporte@gestpar.com.br</p>
            </div>
          </div>

          <h2>Deseja contatar nosso suporte?</h2>
          <h3>
            Preencha os campos abaixo que retornaremos o mais breve possível.
          </h3>
        </div>
        <div className="formMain">
          <div className="input">
            <UnformInput label="Nome*" name="Nome" />
          </div>
          <div className="input">
            <UnformInput label="Email*" name="Email" />
          </div>
          <div className="input">
            <UnformInput label="Telefone" name="Telefone" />
          </div>
          <div className="input">
            <UnformInput label="Assunto*" name="Assunto" />
          </div>
        </div>
        <div className="input">
          <UnformInput
            className="multiline"
            multiline
            label="Mensagem*"
            name="Mensagem"
          />
        </div>
        <div className="cta">
          <SubmitButton disabled={`${formState === "Enviado!" ? "true" : ""}`}>
            {formState}
          </SubmitButton>
        </div>
      </Unform>
    </Container>
    </>
  );
}
