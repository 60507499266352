import { Map } from "google-maps-react";
import styled from "styled-components";
import colors from "../../styles/colors";
import metrics from "../../styles/metrics";

export const MapBlock = styled(Map)`
  height: 400px !important;
  width: 100%;
  position: relative !important;

  @media (max-width: 480px) {
    /* height: 500px; */
    margin-top: 150px;
  }
`;

export const MapInfo = styled.div`
  height: 200px;
  z-index: 5;
  background-color: ${colors.bordo};
  width: 30%;
  top: 100px;
  display: flex;
  flex-direction: column;
  position: absolute;
  justify-content: center;

  left: 5%;
  padding: 0 5%;
  color: white;
  strong {
    font-size: ${metrics.smallTitleFontSize};
  }

  @media (max-width: 480px) {
    /* position: absolute; */
    left: 0;
    top: -150px;
    width: 100%;
    height: 150px;
  }
`;
