import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import api from "../../services/api";
import axios from "axios";

import { FaFacebookF, FaInstagram } from "react-icons/fa";
import {
  Container,
  BannerWrapper,
  ComoAjudar,
  ServicoItem,
  Novidades,
  Produtos,
  Beneficios
} from "./styles";

import { LoadingClip } from "../../styles/global";

import clip_loading from "../../assets/icons/loading.gif";

import img_solucoes from "../../assets/img/img_solucoes.jpg";
import img_produtos from "../../assets/img/img_produtos.jpg";
import img_servicos from "../../assets/img/img_servicos.jpg";

import logo from "../../assets/marca_gestpar.svg";

function SlickSlider(props) {
  var settings = {
    dots: true,
    infinite: true,
    fade: true,
    speed: 1000,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  const { banners } = props;
  // quer saco ter que passar pra vercel
  return (
    <Slider {...settings}>
      {!!banners
        ? banners.map((banner, index) => (
            <BannerWrapper key={index} url={banner.imagem.url}>
              <div className="socials">
                <a
                  href="https://www.facebook.com/Gestpar-Gestão-Inteligente-de-Documentos-105948350772748"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <FaFacebookF size={25} style={{ marginBottom: 15 }} />
                </a>
                <a
                  href="https://www.instagram.com/explore/locations/1208795242465827/gestpar-copiadoras-e-sistemas-de-impressao/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <FaInstagram size={25} />
                </a>
              </div>
              <div className="brand">
                <img src={logo} alt="Gestpar" />
              </div>
              <div className="main">
                <h1>{banner.titulo}</h1>
                {!!banner.url && !!banner.chamada ? (
                  <Link to={banner.url}>
                    <h2>{banner.chamada}</h2>
                  </Link>
                ) : (
                  ""
                )}
              </div>
            </BannerWrapper>
          ))
        : ""}
    </Slider>
  );
}

export default function Home() {
  // State
  const [loading, setLoading] = useState(true);
  const [produtos, setProdutos] = useState(null);
  const [banners, setBanners] = useState(null);
  const [posts, setPosts] = useState([]);

  const getProdutos = async () => {
    const response = await api.get("wp/v2/produtos");
    if (response.data) {
      setProdutos(response.data);
    }
  };

  const getBanners = async () => {
    const { data } = await api.get("api/home");

    if (data.banners) {
      setBanners(data.banners);
    }
  };  

  useEffect(() => {
    document.title = "Gestpar - Home";
    setLoading(true);
    getBanners().then(
      getProdutos().then(() => {
        setLoading(false);
      })
    );
  }, []);

  // Blog (Novidades) request
  useEffect(() => {
    async function getNovidades() {
      const { data } = await api.get('wp/v2/blog');

      setPosts(data.blog);
    };

    getNovidades();
  }, []);

  return (
    <>
      {loading ? (
        <LoadingClip className="fullscreen">
          <img src={clip_loading} alt="Carregando..." />
        </LoadingClip>
      ) : (
        <Container>
          <SlickSlider banners={banners} />
          <ComoAjudar>
            <h3>Como podemos te ajudar?</h3>

            <div className="servicos">
              <Link to="/blog/solucoes-personalizadas">
                <ServicoItem img={img_solucoes} className="servico solucoes">
                  <h4>Soluções</h4>
                </ServicoItem>
              </Link>
              <Link to="/suporte">
                <ServicoItem img={img_produtos} className="servico produtos">
                  <h4>Suporte</h4>
                </ServicoItem>
              </Link>
              <Link to="/servicos">
                <ServicoItem img={img_servicos} className="servico servicos">
                  <h4>Serviços</h4>
                </ServicoItem>
              </Link>
            </div>
          </ComoAjudar>

      <Novidades>
        <div className="mainWrapper">
          <div className="items">
            {posts.map(post => (
              <Link key={post.id} to={`blog/${post.slug}`}>
                <div
                  className="item"
                  style={{ backgroundImage: `url(${post.imagem.url})` }}
                >
                  <h4>{post.titulo}</h4>
                </div>
              </Link>
            ))}
          </div>
        </div>
        <div className="titleWrapper">
          <h2>
            <span>Novi</span>
            <span>da</span>
            <span>des</span>
          </h2>
          <h3>Estamos sempre nos atualizando para melhor lhe atender</h3>
        </div>
      </Novidades>

          {!!produtos ? (
            <Produtos qtdProdutos={produtos.length}>
              {/* conta se tem mais de 1 produto retornado e reorgania as colunas do grid*/}
              <div className="titleWrapper">
                <h2>
                  <span>Equi</span>
                  <span>pamen</span>
                  <span>tos</span>
                </h2>
                <h3>Temos os melhores equipamentos para a sua demanda</h3>
                <Link to="/equipamentos">Ver todos</Link>
              </div>
              <Link to={`/produto/${produtos[0].slug}`}>
                <div className="mainWrapper">
                  <img
                    src={produtos[0].acf.foto_principal.sizes.large}
                    alt={produtos[0].title.rendered}
                  />
                  <h6>{produtos[0].title.rendered}</h6>
                </div>
              </Link>
              {produtos.length > 1 ? ( //conta se tem mais de 1 produto p/ exibir como secundário
                <div className="sideWrapper">
                  {produtos.slice(1, 2).map((
                    produto //mapeia os 2 proximos itens da lista além do primeiro (principal)
                  ) => (
                    <Link key={produto.id} to={`/produto/${produto.slug}`}>
                      <div className="block">
                        <img
                          src={produto.acf.foto_principal.sizes.medium}
                          alt={produto.title.rendered}
                        />
                        <h6>{produto.title.rendered}</h6>
                      </div>
                    </Link>
                  ))}
                </div>
              ) : (
                ""
              )}{" "}
            </Produtos>
          ) : (
            ""
          )}

          <Beneficios />
        </Container>
      )}
    </>
  );
}
