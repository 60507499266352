const width = window.innerWidth;
const metrics = responsiveMetrics(width, this);

function responsiveMetrics(width, metrics) {
  if (width >= 480) {
    //larger device metrics
    metrics = {
      //font-weight metrics
      light: "300",
      regular: "400",
      bold: "700",

      //font-size metrics
      textFontSize: "20px", //textos corridos
      bigTextFontSize: "30px", //textos corridos (maior)
      smallTitleFontSize: "40px", //titulos internos das sections
      titleFontSize: "80px", //titulo da section
      bigTitleFontSize: "80px", //titulo da pagina

      //spacing metrics
      sectionMargin: "120px" //margem padrão entre sections
    };
  } else {
    //mobile devices metrics
    metrics = {
      //font-weight metrics
      light: "300",
      regular: "400",
      bold: "700",

      //font-size metrics
      textFontSize: "15px", //textos corridos
      bigTextFontSize: "20px", //textos corridos (maior)
      smallTitleFontSize: "25px", //titulos internos das sections
      titleFontSize: "35px", //titulo da section
      bigTitleFontSize: "40px", //titulo da pagina

      //spacing metrics
      sectionMargin: "60px" //margem padrão entre sections
    };
  }
  return metrics;
}
export default metrics;
