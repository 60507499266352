import React, { useState, useEffect } from "react";

import api from "../../services/api";

import { ServicosWrapper, Container } from "./styles";
import { LinkButton, PageTitle, LoadingDots } from "../../styles/global";



const Servicos = () => {
  const [servicos, setServicos] = useState("");
  const [loading, setLoading] = useState();
  const [error, setError] = useState("");
  const getServicosData = async () => {
    const response = await api.get("wp/v2/servicos");
    console.log(response.data);
    if (response) {
      setServicos(response.data);
    }
  };

  useEffect(() => {
    setLoading(true);
    getServicosData().then(() => {
      setLoading(false);
    });
  }, []);

  return (
    <ServicosWrapper>
      <Container>
        <PageTitle>Nossos serviços</PageTitle>
        {!!servicos && !loading ? (
          <div className="servicos">
            {servicos.map(servico => (
              <div className="servico" key={servico.id}>
                <h4>{servico.title.rendered}</h4>
                <div
                  dangerouslySetInnerHTML={{ __html: servico.acf.descricao }}
                />
              </div>
            ))}
          </div>
        ) : (
          <LoadingDots dotsColor="white" >
            <div className="dot">
              
            </div>
          </LoadingDots>
        )}
      </Container>
      <div className="cta">
        <LinkButton to="/equipamentos">Conheça nossos equipamentos</LinkButton>
      </div>
    </ServicosWrapper>
  );
};

export default Servicos;
