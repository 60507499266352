import styled from "styled-components";
import colors from "../../styles/colors";
import metrics from "../../styles/metrics";

export const Container = styled.div`
  margin: 0 auto;
  max-width: 1280px;
  width: 95%;
  padding-top: 120px;
  h1 {
    &:before {
      width: 125%;
      left: 0;
    }
  }

  .grid {
    padding: 0 5%;
    padding-top: 120px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);

    .block {
      &:nth-of-type(2) {
        border-left: 1px solid ${colors.preto};
        border-right: 1px solid ${colors.preto};
      }
      display: flex;
      flex-direction: column;
      align-items: center;

      img {
        height: 90px;
      }

      h2 {
        font-size: ${metrics.smallTitleFontSize};
        margin: 20px 0;
        text-align: center;
      }

      ul {
        display: block;
        text-align: left;
        li {
          list-style: none;
          margin-bottom: 15px;
          a {
            transition: 0.5s ease;
            color: ${colors.preto};

            &:hover {
              color: ${colors.bordo};
              text-decoration: none;
            }
          }
        }
      }
    }
  }

  @media (max-width: 480px) {
    h1 {
      padding-left: 20px;
      &:before {
        /* width: 100%; */
      }
    }

    .grid {
      grid-template-columns: 1fr;
      padding-top: 50px;
      .block {
        padding: 50px 0;
        border: none;
        border-bottom: 1px solid ${colors.preto};
        &:nth-of-type(2) {
          border-left: none;
          border-right: none;
        }
        &:last-of-type{
          border-bottom: none;
        }
      }
    }
  }
`;
