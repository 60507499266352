import styled from "styled-components";
import colors from "../../styles/colors";
import metrics from "../../styles/metrics";

import { Form, Input } from "@rocketseat/unform";

export const Container = styled.div`
  margin: 0 auto;
  max-width: 1280px;
  width: 95%;
  padding-top: 120px;
  h1 {
    &:before {
      left: 0;
      width: calc(100% + 25%);
    }
  }

  @media (max-width: 480px){
    h1{
      font-size: 50px;
    }
  }

  .precisando {
    margin-bottom: 45px;

    div {
      margin-top: 10px;

      p {
        font-size: 24px;
        margin-bottom: 8px;
      }
    }
  }
`;

export const Unform = styled(Form)`
  padding: 0 150px;
  padding-top: 120px;
  .clienteOptions {
    .clienteOption {

      input[type="radio"] {
        /* opacity: 0; */
        height: 65px;
        margin-right: 20px;
        /* width: 85px; */
      }
      position: relative;
      z-index: 1;
      /* height: 25px; */
      display: flex;
      flex-direction: row;
      justify-content: left;
      align-items: center;
      /* padding: 0 10px; */
      opacity: 0.5;
      transition: 0.5s ease;
      cursor: pointer;
      &:hover {
        opacity: 0.7;
      }
      &.active {
        opacity: 1;
        strong{
        color: white;
          &:before {
            width: 300px;
          }
        }
      }
      
      strong {
        display: block;
        position: relative;
        /* font-size: ${metrics.bigTextFontSize}; */
        font-size: 20px;
        font-weight: ${metrics.bold};
        &:before {
        z-index: -1;
        background-color: ${colors.bordo};
        width: 0;
        transition: 0.5s ease;
        bottom: 0;
        height: 20px;
        left: -1%;
        content: "";
        position: absolute;
      }
      }
      span {
        font-size: 15px;
      }
    }
  }

  .formMain {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 60px;
    grid-row-gap: 15px;
    padding: 20px 0;
  }

  .cta {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 70px 0;
  }

  @media (max-width: 480px){
    padding: 0px 2.5%;
    padding-top: 50px;
    .formMain{
      grid-template-columns: 1fr;
    
    }
  }

`;

export const UnformInput = styled(Input)`
  display: flex;
  flex-direction: column;
  border: none;
  border-bottom: 1px solid ${colors.preto};
  width: 100%;
  height: 40px;

  &.multiline {
    height: 130px;
  }
`;
