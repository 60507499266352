import styled from "styled-components";
import colors from "../../styles/colors";
import metrics from "../../styles/metrics";

export const Nav = styled.nav`
  position: fixed;
  cursor: pointer;
  top: 65px;
  right: ${props => (props.isMenuShown ? "100%" : "0")};
  height: 150px;
  width: 130px;
  background-color: #414141;
  z-index: 15;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.5s ease;

  &:after {
    content: "";
    transition: 0.2s ease;
    position: absolute;
    height: 100%;
    width: 0;
    background-color: white;
    left: 0;
    top: 0;
  }
  &.min {
    width: 100px;
    height: 100px;
    img {
      height: 35px;
    }
  }
  &:hover {
    &:after {
      width: 5px;
    }
    width: 150px;
    &.min {
      width: 110px;
    }
    background-color: ${colors.bordo};
    img {
      filter: brightness(100);
    }
  }
  img {
    transition: 0.1s;
    height: 50px;
  }
  span {
    transform: rotate(90deg);
    color: white;
    font-weight: ${metrics.bold};
    font-size: 18px;
    text-transform: inherit;
    margin: 0 -5px;
  }

  @media (max-width: 480px) {
    top: ${props => (props.isMenuShown ? "100%" : "0")};
    right: 0px;
    height: 80px;

    &.min {
      /* width: 130px; */
      height: 60px;
      img {
        height: 35px;
      }
    }

    span {
      font-size: 12px;
    }
  }
`;

export const MenuWrapper = styled.div`
  height: 100vh;
  width: 100%;
  background-color: #414141;
  position: fixed;
  z-index: 99;
  transition: 0.5s ease;
  /* left: 0; */
  left: ${props => (props.active ? "0" : "100%")};

  display: grid;
  grid-template-columns: 0.9fr 1fr;

  .menuWrapper {
    display: grid;
    grid-template-columns: 0.25fr 1fr;
    align-items: center;
    .socials {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;

      svg {
        color: white;
        transition: 0.5s ease;
        cursor: pointer;
        &:hover {
          color: ${colors.bordo};
        }
      }
    }

    ul {
      img {
        display: none;
      }
      position: relative;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      li {
        list-style-type: none;

        a {
          line-height: 60px;
          font-size: 35px;
          font-weight: ${metrics.bold};
          text-transform: inherit;
          color: white;
          text-decoration: none;
          position: relative;
          z-index: 1;
          &:before {
            position: absolute;
            z-index: -1;
            content: "";
            width: 0;
            height: 35px;
            left: -10px;
            top: 10px;
            background-color: ${colors.bordo};
            transition: 0.5s ease;
          }
          &:hover {
            &:before {
              width: 300px;
            }
          }
        }
      }
    }
  }

  .infoWrapper {
    align-self: center;
    /* display: flex; */
    flex-direction: column;
    padding: 0 60px;
    /* align-items: flex-start;
    justify-content: center;
    text-align: left; */
    img {
      align-self: center;
      margin-bottom: 70px;
      height: 76px;
    }
    p {
      line-height: 30px;
      color: #fff;
      font-size: 20px;
      font-weight: ${metrics.bold};
      margin-bottom: 35px;
    }
  }

  @media (max-width: 480px) {
    left: auto;
    height: 100vh;
    grid-template-columns: 1fr;
    justify-content: flex-end;

    top: ${props => (props.active ? "0" : "-110vh")};
    .menuWrapper {
      grid-template-columns: 1fr;
      padding: 0 30px;
      height: 100vh;
      /* padding-top: 50px; */
      .socials {
        display: none;
      }

      ul {
        text-align: right;
        li {
          a {
            font-size: 30px;
            line-height: 50px;
          }
        }
        img {
          display: inline;
          max-height: 50px;
        }
      }
    }
    .infoWrapper {
        display: none;
    }
  }
`;

export const CloseMenu = styled.div`
  position: absolute;
  cursor: pointer;
  top: 50%;
  transform: translatey(-50%);
  left: 0;
  height: 150px;
  width: 130px;
  background-color: #414141;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.5s ease;

  &:after {
    content: "";
    transition: 0.2s ease;
    position: absolute;
    height: 100%;
    width: 0;
    background-color: white;
    left: 0;
    top: 0;
  }
  &:hover {
    &:after {
      width: 5px;
    }
    width: 150px;
    &.min {
      width: 110px;
    }
    background-color: ${colors.bordo};
    img {
      filter: brightness(100);
    }
  }
  img {
    transition: 0.1s;
    height: 50px;
  }
  span {
    transform: rotate(90deg);
    color: white;
    font-weight: ${metrics.bold};
    font-size: 18px;
    text-transform: inherit;
    margin: 0 -15px;
  }

  @media (max-width: 480px) {
    top: 0;
    right: 20px;
    height: 80px;
    transform: scale(.75);
    span {
      font-size: 12px;
    }
  }

  @media (max-width: 320px) {
    transform: scale(.7);
    left: -15px;
    top: -5px;
  }
`;
