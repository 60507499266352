import styled from "styled-components";
import colors from "../../styles/colors";
import metrics from "../../styles/metrics";

export const ServicosWrapper = styled.section`
  .cta {
    display: flex;
    align-items: center;
    justify-content: center;
    a {
      margin: 70px auto;
    }
  }

  h1:before {
    width: calc(100% + 10%);
  }

  @media (max-width: 480px){
    h1{
      &:before{
        margin-left: -25%;
        height: 150px;
      }
    }
  }
`;

export const Container = styled.div`
  padding: 120px 2.5%;
  background-color: ${colors.preto};
  min-height: 900px;
  .servicos {
    padding-top: 110px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 70px;
    .servico {
      margin-bottom: 100px;
      color: white;

      h4 {
        font-size: 35px;
        font-weight: ${metrics.bold};
        margin-bottom: 15px;
        max-width: 80%;
      }
      div {
        font-size: 15px;
        line-height: 30px;
      }
    }
  }

  @media (max-width: 480px) {
    
    .servicos {
      padding-top: ${metrics.sectionMargin};
      grid-template-columns: 1fr;
      grid-row-gap: 30px;

      .servico {
        margin-bottom: 0;
      }
    }
  }
`;
