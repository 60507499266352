import React from "react";
import { Link } from "react-router-dom";

import { Marker, GoogleApiWrapper, InfoWindow } from "google-maps-react";
import { MapInfo, MapBlock } from "./styles";
export class MapContainer extends React.Component {
  render() {
    const mapProps = {
      center: {
        lat: -25.1253996,
        lng: -50.1536175
      },
      zoom: 16
    };
    return (
      <MapBlock
        initialCenter={{
          lat: mapProps.center.lat,
          lng: mapProps.center.lng
        }}
        google={this.props.google}
        zoom={17}
      >
        <a href="https://goo.gl/maps/taHhUvp7baLqmqC56" target="_blank">
          <MapInfo>
            <strong>Onde Estamos?</strong>
            <span>
              R. Brasil, 262. Oficinas <br />
              Ponta Grossa, PR - Centro
            </span>
          </MapInfo>
        </a>

        <Marker name={"Gestpar"} />

        <InfoWindow>
          <div>
            <h1>Gestpar</h1>
          </div>
        </InfoWindow>
      </MapBlock>
    );
  }
}

export default GoogleApiWrapper(props => ({
  apiKey: "AIzaSyDS7AmoIVR_3fsvx1dwLe2_AipBDKLDm0s",
  language: props.language
}))(MapContainer);
