import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { FaInstagram, FaFacebookF } from "react-icons/fa";
import { Nav, MenuWrapper, CloseMenu } from "./styles";

import clip from "../../assets/icons/clip_bordo.svg";
import logo from "../../assets/marca_gestpar_white.svg";

export default function Header() {
  const [isMenuShown, setIsMenuShown] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      let pos = window.scrollY;
      try {
        const nav = document.getElementById("menuTrigger");
        if (pos >= 450) {
          nav.classList.add("min");
        } else {
          nav.classList.remove("min");
        }
      } catch (e) {
        console.error(e);
      }
    });
  });

  return (
    <>
      <Nav
        id="menuTrigger"
        isMenuShown={isMenuShown}
        onClick={() => setIsMenuShown(true)}
      >
        <img src={clip} alt="Menu" />
        <span>Menu</span>
      </Nav>
      <Menu isMenuShown={isMenuShown} setIsMenuShown={setIsMenuShown} />
    </>
  );
}

const Menu = props => {
  const { isMenuShown, setIsMenuShown } = props;

  return (
    <>
      <MenuWrapper active={isMenuShown}>
        <div className="menuWrapper">
          <div className="socials">
            <a
              href="https://www.facebook.com/Gestpar-Gestão-Inteligente-de-Documentos-105948350772748"
              rel="noopener noreferrer"
              target="_blank"
            >
              <FaFacebookF size={25} style={{ marginBottom: 15 }} />
            </a>
            <a
              href="https://www.instagram.com/explore/locations/1208795242465827/gestpar-copiadoras-e-sistemas-de-impressao/"
              rel="noopener noreferrer"
              target="_blank"
            >
              <FaInstagram size={25} />
            </a>
          </div>
          <ul>
            <li>
              <Link to="/" onClick={() => setIsMenuShown(false)}>
                <img src={logo} alt="Gestpar" />
              </Link>
            </li>
            <li>
              <Link to="/" onClick={() => setIsMenuShown(false)}>
                Home
              </Link>
            </li>
            <li>
              <Link to="/sobre" onClick={() => setIsMenuShown(false)}>
                Sobre
              </Link>
            </li>
            <li>
              <Link to="/servicos" onClick={() => setIsMenuShown(false)}>
                Serviços
              </Link>
            </li>
            <li>
              <Link to="/equipamentos" onClick={() => setIsMenuShown(false)}>
                Equipamentos
              </Link>
            </li>
            <li>
              <Link to="/contato" onClick={() => setIsMenuShown(false)}>
                Contato
              </Link>
            </li>
            <li>
              <Link to="/suporte" onClick={() => setIsMenuShown(false)}>
                Suporte
              </Link>
            </li>
            <li>
              <Link to="/downloads" onClick={() => setIsMenuShown(false)}>
                Downloads
              </Link>
            </li>
            <li>
              <Link to="/blog" onClick={() => setIsMenuShown(false)}>
                Blog
              </Link>
            </li>
          </ul>
        </div>
        <div className="infoWrapper">
          <img src={logo} alt="Gestpar" />
          <p>
            R. Brasil, 262 - Oficinas <br />Ponta Grossa - PR, 84036-010
          </p>
          <p>42 3026-2222</p>
          <p>
            informacoes@gestpar.com.br
          </p>
        </div>
        <CloseMenu onClick={() => setIsMenuShown(false)}>
          <img src={clip} alt="Menu" />
          <span>Fechar</span>
        </CloseMenu>
      </MenuWrapper>
    </>
  );
};
