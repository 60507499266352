import styled from "styled-components";

export const Topo = styled.div `
  position: relative;
  height: 400px;
  background-size: cover;
  background-position: center;

  .content {
    max-width: 970px;
    width: 60%;
    margin: auto;
    height: 400px;
    display: flex;
    align-items: flex-end;

    h1 {
      background: #000;
      color: #fff;
      padding: 30px 35px;
      height: auto;
      margin-bottom: 70px;
      font-size: 60px;
      line-height: 60px;
      box-sizing: border-box;
      display: block;
      max-width: 80%;
    }
  }

  @media (max-width: 480px){
    .content {
      width: 100%;
      max-width: 100%;

      h1 {
        width: 100%;
        font-size: 34px;
        line-height: 40px;
        max-width: 100%;
        background: rgba(0,0,0,.5);
        margin-bottom: 0;
        padding: 20px 7.5%;
      }
    }
  }
`;

export const Container = styled.div`
  margin: 0 auto;
  max-width: 970px;
  width: 60%;

  .BlogWrapper {
    margin: 50px 0;

    p, div, ul {
      font-size: 18px;
      line-height: 1.5;
      margin-bottom: 25px;
    }

    ul {
      padding-left: 20px;
    }

    a {
      text-decoration: none;
    }

    .share {
      display: flex;
      flex-direction: row;
      margin-top: 30px;

      > div {
        margin-right: 10px;
      }
    }

    button {
      margin: 60px auto 0 auto;
      display: block;
      background: none;
      font-weight: bold;
      border: none;
      position: relative;
      border-bottom: 5px solid #980C3A;
      width: 260px;
      font-size: 20px;
      padding-bottom: 7.5px;
      cursor: pointer;
    }
  }

  @media (max-width: 480px){
    max-width: 100%;
    width: 100%;

    .BlogWrapper {
      width: 85%;
      max-width: 85%;
      margin: 60px auto;
    }
  }
`;