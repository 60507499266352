// Importa Libs do React
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ReactHtmlParser from 'react-html-parser';
import {
   FacebookShareButton,
   LinkedinShareButton,
   TwitterShareButton,
   WhatsappShareButton,
   EmailShareButton,
   FacebookIcon,
   TwitterIcon,
   WhatsappIcon,
   LinkedinIcon,
   EmailIcon,
 } from 'react-share';

// Importa o loading do início
// import clip_loading from "../../assets/icons/loading.gif";

// Importa os CSS
import { Topo, Container } from "./styles";

// Importa o serviço da API
import api from "../../services/api";

// Inicia a pagina do blog
export default function SingleBlog({match}) {

   // Variáveis que vai receber minhas informações
   const [post, setPosts] = useState([]);          // Estado inicial recebe um array vazio
   // const [loading, setLoading] = useState(true);      // Seta o estado inicial do loading

   // Declara o useEffect para setar o loading
   // useEffect(() => {
   //    setLoading(true);
   // }, []);

   // Declara outro useEffect para os posts
   useEffect(() => {

      // Faz a requisição e já atribui o retorno dela ao estado dos posts
      async function getPosts() {
         // Requisição para API
         const { data } = await api.get(`wp/v2/blog/${match.params.slug}`);

         // Já chama a função para setar os dados no estado
         setPosts(data);
      };

      // Já chama a função para ficar dentro do useEffect
      getPosts();
   }, []);

   /**
    * Retorna o JSX pra montar a tela
    */
   return ( <>
      <Topo style={{ backgroundImage: `url(${!!post.imagem && post.imagem.url})` }}>
         <div className="content">
            <h1>{!!post.titulo && post.titulo}</h1>
         </div>
      </Topo>
      
      <Container> 
         <div className="BlogWrapper">
            
            {!!post.conteudo && ReactHtmlParser(post.conteudo)}

            <div className="share">
               <FacebookShareButton children={<FacebookIcon size={32} round={false} />} url={window.location.href}></FacebookShareButton>
               <LinkedinShareButton children={<LinkedinIcon size={32} round={false} />} url={window.location.href}></LinkedinShareButton>
               <TwitterShareButton children={<TwitterIcon size={32} round={false} />} url={window.location.href}></TwitterShareButton>
               <WhatsappShareButton children={<WhatsappIcon size={32} round={false} />} url={window.location.href}></WhatsappShareButton>
               <EmailShareButton children={<EmailIcon size={32} round={false} />} url={window.location.href}></EmailShareButton>
            </div>

            <Link to={`/blog`}>
               <button>Voltar ao blog</button>
            </Link>
         </div>
      </Container>
   </>);
}