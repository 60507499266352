import React, { useState, useEffect } from "react";
import ReactHtmlParser from 'react-html-parser';

import api from "../../services/api";

import { Main, Galeria } from "./styles";
import { LinkButton } from "../../styles/global";
import logo from "../../assets/marca_gestpar.svg";

export default function Sobre() {
  const [sobre, setSobre] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
  }, []);

  // Declara outro useEffect para os posts
  useEffect(() => {
    // Faz a requisição e já atribui o retorno dela ao estado dos posts
    async function getSobre() {
        // Requisição para API
        const { data } = await api.get('api/sobre');
        
        // Já chama a função para setar os dados no estado
        setSobre(data[0]);

        console.log( data[0] );
    };

    // Já chama a função para ficar dentro do useEffect
    getSobre();
  }, []);

  return (
    <>
      <Main>
        <div className="mainWrapper">
          <img src={logo} alt="Gestpar" />
          <h1>Sobre nós</h1>
          <div className="sobre">
            {!!sobre && ReactHtmlParser(sobre.texto)}
          </div>
          <div className="cta">
            <LinkButton to="/servicos">Conheça os nossos serviços</LinkButton>
            <LinkButton to="/equipamentos">Conheça os nossos equipamentos</LinkButton>
          </div>
        </div>
        <div className="imgWrapper" />
      </Main>
      {/* <Galeria>
        {!!sobre
          ? sobre.galeria.map(foto => (
              <a href={foto.url} data-fancybox="gallery">
                <div
                  className="foto"
                  style={{ backgroundImage: `url(${foto.url})` }}
                />
              </a>
            ))
          : ""}
      </Galeria> */}
    </>
  );
}
