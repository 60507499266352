import React from "react";
import { HashRouter, BrowserRouter } from "react-router-dom";
import TagManager from 'react-gtm-module';

import GlobalStyle from "./styles/global";
import Routes from "./routes/routes";

import Header from "./components/Header";
import Footer from "./components/Footer";

const Router =
  process.env.REACT_APP_SERVER_ENV === "development"
    ? HashRouter
    : BrowserRouter;

const tagManagerArgs = {
  gtmId: 'GTM-53S5NFB'
}

// Iniciar o GTM
TagManager.initialize(tagManagerArgs)
    
const App = () => (
  <Router>
    <GlobalStyle />
    <Header />
    <Routes />
    <Footer />
  </Router>
);

export default App;
