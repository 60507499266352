import styled from "styled-components";
import colors from "../../styles/colors";
import metrics from "../../styles/metrics";

export const ProdutosWrapper = styled.section`
  padding: 120px 0;
  .title {
    display: grid;
    grid-template-columns: 1fr 0.4fr;
    grid-column-gap: 30px;

    h1 {
      color: #ffffff;
      font-size: 65px;

      &:before {
        background-color: #414141;
        width: 100%;
        height: 150px;

        @media (max-width: 480px) {
          height: 125px;
        }
      }
    }
    h2 {
      z-index: 1;
      font-weight: ${metrics.regular};
      font-size: 24px;
      text-transform: inherit;
      max-width: 70%;
    }
  }

  @media (max-width: 480px) {
    .title {
      padding-left: 20px;
      grid-template-columns: 1fr;
      grid-row-gap: 50px;
      h1 {
        font-size: ${metrics.bigTitleFontSize};
        padding-left: 0;
      }
    }
  }
`;

export const Container = styled.div`
  margin: 0 auto;
  max-width: 1280px;
  width: 95%;

  .listaProdutos {
    margin-top: ${metrics.sectionMargin};
    display: grid;
    grid-template-columns: 30% 30% 30%;
    grid-gap: 30px 5%;
    
    a {
      text-decoration: none;
      .produto {
        display: flex;
        justify-content: center;
        flex-direction: column;
        height: 400px;
        position: relative;
        /* transition: 0.5s ease; */

        &:before {
          bottom: 0;
          position: absolute;
          width: 100%;
          height: 0;
          content: "";
          z-index: 1;
          background-color: ${colors.bordo};
          transition: 0.3s ease;
        }

        &:hover {
          &:before {
            height: 400px;

            @media (max-width: 480px) {
              height: auto;
            }
          }
          img {
            /* height: 210px; */
            display: none;
          }
          h6 {
            opacity: 1;
            display: block;
            top: 50%;
            
            &:after {
              opacity: 1;
              bottom: -100px;
            }

            @media (max-width: 480px) {
              top: inherit;
            }
          }
        }

        img {
          transition: 0.3s ease;
          align-self: center;
          margin-bottom: 15px;
          /* max-height: 330px; */
          width: 100%;
          height: auto;
          object-fit: contain;
        }
        h6 {
          z-index: 5;
          position: absolute;
          top: 60%;
          transform: translatey(-50%);
          transition: 0.5s ease;
          padding: 0 15px;
          font-size: ${metrics.bigTextFontSize};
          text-align: center;
          color: white;
          opacity: 0;
          transition: 0.5s ease;
          /* display: none; */
          &:after {
            content: "+";
            color: white;
            font-size: ${metrics.smallTitleFontSize};
            left: 50%;
            position: absolute;
            font-weight: ${metrics.bold};
            transform: translateX() (-50%);
            bottom: -120px;
            /* opacity: 0; */
            transition: 0.5s ease;
          }
        }
      }
    }
  }

  .cta {
    display: flex:
    align-items: center;  
    justify-content: center;
    a {
        margin: 70px auto;
    }
  }

  @media (max-width: 480px){
    .listaProdutos{
      grid-template-columns: 1fr;
      grid-row-gap: 10px;
      a{
        .produto{
          height: 200px;
          display: grid;
          grid-template-columns: .7fr 1fr;
          align-items: center;
          img{
            max-height: 100%;
            max-width: 90%;
            margin-bottom: 0;
          }

          h6{
            height: 100%;
            display: flex;
            background-color: ${colors.bordo};
            align-items: center;

            opacity: 1;
            position: relative;
            top: auto;
            transform: none;

            &:after{
              bottom: 20px;
            }
          }
        }
      }
    }
  }
`;
