import styled from "styled-components";
import colors from "../../styles/colors";
import metrics from "../../styles/metrics";
import { transparentize } from "polished";

export const Container = styled.div`
  /* width: 95%; */
  /* margin: 0 auto; */
  /* max-width: 1280px; */
  .slick-dots {
    z-index: 2;
    position: absolute;
    top: 85%;
    left: 30%;
    /* transform: tranalate */
    width: auto;

    li {
      background-color: ${colors.preto};
      width: 45px;
      height: 15px;
      transition: 0.3s ease;
      &:hover {
        background-color: ${colors.cinza};
      }
      &.slick-active {
        background-color: ${colors.bordo};
      }
      button {
        &:before {
          content: none;
        }
      }
    }
  }
  @media (max-width: 480px) {
    width: 100%;

    .slick-dots {
      top: 70%;
      left: 20px;
    }
  }
`;

export const BannerWrapper = styled.section`
  height: 100vh;
  display: grid !important;
  grid-template-columns: 60px .8fr 1fr;

  .socials{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    svg{
      color: ${colors.preto};
      transition: .5s ease;
      cursor: pointer;
      &:hover{
        color: ${colors.bordo};
      }
    }
  }

  .brand{
    display: flex;
    justify-content: center;
    padding-top: 30%;
    img{
      height: 95px;
    }
  }
  .main{
    height: 100%;
    width: 100%;
    background-size: cover;
    background-position: center;
    background-image: url('${props => props.url}');

    h1 {
      position: absolute;
      text-align: right;
      display: inline-block;
      text-transform: inherit;
      padding: 0 50px;
      max-width: 70%;
      line-height: 100px;
      z-index: 5;
      font-size: ${metrics.bigTitleFontSize};
      color: white;
      top: 65%;
      transform: translatey(-50%);
      right: 45px;
      padding-bottom: 30px;

      &:before{
        content: '';
        height: 100%;
        width: 110%;
        left: -5%;
        background-color: ${colors.preto};
        z-index: -1;
        position: absolute;
        top: -5px;
        opacity: .6;
      }
      
    }

    h2{
      position: absolute;
      text-align: right;
      display: inline-block;
      z-index: 5;
      color: white;
      text-transform: inherit;
      top: 81%;
      right: 45px;
      padding: 0 5%;
      transition: .5s ease;
      &:hover{
        color: ${colors.bordo};

        &:before{
          height: 35px;
          bottom: -5px;
          background-color: white;
        }
      }

      &:before{
        transition: .5s ease;
        width: 100%;
        left: 0;
        height: 15px;
        bottom: 5px;
        content: '';
        background-color:${colors.bordo};
        position: absolute;
        z-index: -1;

      }
    }
  }

  @media (max-width: 480px){
    display: block !important;
    .brand{
      display: none;
    }
    .socials{
      display: none;
    }
    .main{
      h1{
        text-align: left;
        top:auto;
        bottom: 35%;
        padding: 0 10px;
        line-height: 40px;
        font-size: 30px;
        transform: none;
        right: auto;
        left: 20px;
      }
      h2{
        &:before{
          height: 60px;
          bottom: -5px;
        }
      }
    }
  }
  

`;

export const ComoAjudar = styled.section`
  margin: ${metrics.sectionMargin} 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  a {
    text-decoration: none;
  }
  h3 {
    text-align: center;
    text-transform: inherit;
    font-size: ${metrics.smallTitleFontSize};
    position: relative;
    z-index: 1;
    display: inline-block;
    margin-bottom: 50px;
    /* &:after {
      content: "";
      z-index: -1;
      width: 200px;
      height: 20px;
      top: 15px;
      position: absolute;
      background-color: ${colors.bordo};
      right: 0;
    } */
  }

  .servicos {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    width: 100%;
  }

  @media (max-width: 480px) {
    .servicos {
      grid-template-columns: 1fr;
    }
  }
`;

export const ServicoItem = styled.div`
  height: 480px;
  position: relative;
  overflow: hidden;
  &:after {
    content: "";
    position: absolute;
    width: 90%;
    right: 5%;
    z-index: 1;
    height: 360px;
    top: 100%;
    background-size: cover;
    background-position: center;
    background-image: url(${props => props.img});
    /* opacity: 0; */
    transition: 0.5s ease;
  }

  &:hover {
    &:after {
      top: 50px;
    }
    h4 {
      bottom: 10px;
    }
  }
  h4 {
    position: absolute;
    bottom: 60px;
    font-size: 55px;
    text-transform: inherit;
    padding: 0 40px;
    z-index: 5;
    transition: 0.5s ease;
  }

  &.solucoes {
    background-color: ${colors.preto};
    h4 {
      color: white;
    }
  }
  &.produtos {
    background-color: ${colors.cinza};
    h4 {
      color: ${colors.preto};
    }
  }
  &.servicos {
    h4 {
      color: white;
    }
    background-color: ${colors.bordo};
  }

  @media (max-width: 480px) {
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    h4 {
      font-size: 40px;
      padding: 0;
      position: relative;
      bottom: auto;
      text-align: center;

      &:after {
        content: none;
      }
    }
  }
`;

export const Novidades = styled.section`
  display: grid;
  grid-template-columns: 1fr 0.8fr;
  margin-bottom: ${metrics.sectionMargin};
  a {
    text-decoration: none;
  }
  .mainWrapper {
    padding: 35px 0;
    display: flex;
    align-items: center;
    position: relative;
    z-index: 5;
    .items {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      height: 95%;
      border: 15px solid white;
      margin-left: -15px;
      .item {
        background-color: black;
        background-size: cover;
        background-position: center;
        height: 100%;
        width: 100%;
        padding: 50px;
        position: relative;
        z-index: 1;
        &:hover {
          &:after {
            background-color: ${colors.preto};
            width: 80%;
            height: 90%;
            bottom: 5%;
            left: 10%;
          }
          h4 {
            &:after {
              opacity: 1;
              left: 470px;
            }
          }
        }

        &:after {
          background-color: ${transparentize(0.6, colors.preto)};
          width: 100%;
          height: 100%;
          position: absolute;
          bottom: 0;
          left: 0;
          content: "";
          z-index: 2;
          transition: 0.5s ease;
        }
        h4 {
          transition: 0.5s ease;
          z-index: 5;
          position: absolute;
          width: 500px;
          bottom: 270px;
          right: -110px;
          font-size: ${metrics.smallTitleFontSize};
          color: white;
          transform: rotate(-90deg);

          &:after {
            content: "+";
            color: ${colors.azul};
            left: 400px;
            opacity: 0;
            transition: 0.3s ease;
            position: absolute;
            font-size: ${metrics.smallTitleFontSize};
          }
        }
      }
    }
  }
  .titleWrapper {
    position: relative;
    z-index: 1;

    &:before {
      background-color: ${colors.preto};
      z-index: -1;
      content: "";
      position: absolute;
      height: 100%;
      top: 0;
      width: 110%;
      right: 0;
    }
    padding: 220px 100px 220px 200px;
    h2 {
      font-size: ${metrics.titleFontSize};
      line-height: 65px;
      display: flex;
      flex-direction: column;
      line-height: ${metrics.titleFontSize};
      text-align: right;
      color: white;
      justify-content: flex-end;
      position: relative;
      z-index: 1;
      margin-bottom: 45px;
      font-weight: ${metrics.bold};
      &:before {
        right: -60px;
        content: "";
        background-color: ${colors.bordo};
        width: 400px;
        height: 30px;
        position: absolute;
        bottom: -15px;
        z-index: -1;
      }
    }
    h3 {
      font-size: ${metrics.bigTextFontSize};
      color: white;
      font-weight: ${metrics.regular};
      text-align: right;
      line-height: 40px;
    }
  }

  @media (max-width: 480px) {
    display: flex;
    flex-direction: column-reverse;

    .mainWrapper {
      padding: 0;
      /* display: none; */

      .items {
        grid-template-columns: 1fr;
        border: none;
        margin-left: auto;

        .item {
          display: flex;
          height: 100px;
          align-items: center;
          justify-content: center;
          padding: 0;

          &:after {
            content: none;
          }
          h4 {
            &:after {
              content: none;
            }
            transform: none;
            position: relative;
            bottom: auto;
            right: auto;
          }
        }
      }
    }
    .titleWrapper {
      padding: 20px;
      h3 {
        font-size: 15px;
        line-height: 25px;
      }
      h2 {
        display: block;
        margin-bottom: 5px;
        &:before {
          /* bottom: 14px;
          width: 60%;
          right: 0; */
          content: none;
        }
      }
    }
  }
`;
export const Produtos = styled.section`
  display: grid;
  grid-template-columns: ${props =>
    props.qtdProdutos > 1
      ? "1fr 1.1fr 1fr"
      : "1fr 1.1fr"}; /* se tem mais de 1 produto retornado, separa em 3 colunas, se nao, só em 2*/
  margin-bottom: ${metrics.sectionMargin};
  a {
    text-decoration: none !important;
  }
  .mainWrapper {
    height: 750px;
    background-color: ${colors.bordo};

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;

    &:after {
      content: "+";
      color: white;
      font-size: ${metrics.bigTextFontSize};
      left: 50%;
      position: absolute;
      font-weight: ${metrics.bold};
      transform: translateX() (-50%);
      bottom: 0;
      opacity: 0;
      transition: 0.5s ease;
    }
    img {
      height: 400px;
      margin-bottom: 40px;
      transition: 0.5s ease;
      max-width: 100%;
      align-self: center;
      max-height: 90%;
    }
    h6 {
      opacity: 0;
      font-size: ${metrics.bigTextFontSize};
      color: white;
      text-align: center;
      transition: 0.5s ease;
      padding: 0 60px;
    }

    &:hover {
      img {
        transform: scale(1.1);
      }
      h6 {
        opacity: 1;
      }
      &:after {
        opacity: 1;
        bottom: 30px;
      }
    }
  }

  .titleWrapper {
    padding: 100px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    h2 {
      font-size: ${metrics.titleFontSize};
      display: flex;
      flex-direction: column;
      line-height: 65px;
      text-align: right;
      color: ${colors.preto};
      justify-content: flex-end;
      position: relative;
      z-index: 1;
      margin-bottom: 45px;
      font-weight: ${metrics.bold};
      &:before {
        right: -60px;
        content: "";
        background-color: ${colors.bordo};
        width: 400px;
        height: 30px;
        position: absolute;
        bottom: -15px;
        z-index: -1;
      }
    }
    h3 {
      font-size: ${metrics.bigTextFontSize};
      color: ${colors.preto};
      font-weight: ${metrics.regular};
      text-align: right;
      line-height: 40px;
      margin-bottom: 70px;
    }
    a {
      color: ${colors.preto};
      font-size: 25px;
      text-transform: inherit;
      font-weight: ${metrics.bold};
      text-align: right;
      position: relative;
      z-index: 1;
      transition: 0.5s ease;
      &:hover {
        color: white;

        &:before {
          left: -5%;
          opacity: 1;
        }
      }
      &:before {
        background-color: ${colors.bordo};
        left: -50%;
        height: 40px;
        opacity: 0;
        transition: 0.5s ease;
        content: "";
        width: 110%;
        top: -5px;
        position: absolute;
        z-index: -1;
      }
    }
  }

  .sideWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;

    .block {
      display: flex;
      justify-content: center;
      flex-direction: column;
      padding: 0 100px;
      position: relative;
      &:hover {
        img {
          height: 210px;
        }
        h6 {
          opacity: 1;
        }
        &:after {
          opacity: 1;
          bottom: -25px;
        }
      }
      &:after {
        content: "+";
        color: ${colors.bordo};
        font-size: ${metrics.textFontSize};
        left: 50%;
        position: absolute;
        font-weight: ${metrics.bold};
        transform: translateX() (-50%);
        bottom: -35px;
        opacity: 0;
        transition: 0.5s ease;
      }
      img {
        transition: 0.3s ease;
        align-self: center;
        margin-bottom: 15px;
        height: 200px;
      }
      h6 {
        transition: 0.5s ease;
        font-size: ${metrics.textFontSize};
        text-align: center;
        color: ${colors.preto};
        opacity: 0;
      }
    }
  }

  @media (max-width: 480px) {
    /* display: none; */
    grid-template-columns: 1fr;
    .titleWrapper {
      padding: 0 2.5%;

      h2 {
        display: block;
        margin-bottom: 5px;

        &:before {
          /* right: 0;
          width: 110%; */
          content: none;
        }
      }
      h3 {
        font-size: 15px;
        line-height: 20px;
        margin-bottom: 30px;
      }
      a {
        margin-bottom: 50px;
      }
    }
    .mainWrapper {
      height: auto;
      padding: 20px 0;
      img {
        height: auto;
        max-height: 200px;
      }
      h6 {
        opacity: 1;
        font-size: 20px;
      }
    }
    .sideWrapper {
      height: 200px;
      flex-direction: row;
      .block {
        padding: 0;
        img {
          height: 150px;
          margin-bottom: 0;
        }
        h6 {
          display: none;
        }
      }
    }
  }
`;
export const Beneficios = styled.section``;
