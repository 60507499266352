import styled from "styled-components";
import colors from "../../styles/colors";
import metrics from "../../styles/metrics";

import { Form, Input } from "@rocketseat/unform";

export const Container = styled.div`
  margin: 0 auto;
  max-width: 1280px;
  width: 95%;
  
  @media screen and (max-width: 480px) {
    margin: auto;
    width: 80%;
  }
  
  h1 {
    font-size: 40px;

    @media screen and (max-width: 480px) {
      font-size: 25px;
    }

    &:before {
      width: 125%;
      left: 0;
    }
  }

`;

export const Unform = styled(Form)`
  padding: 0 150px;
  padding-top: 120px;


  .precisando {
    margin-bottom: 45px;

    div {
      margin-top: 10px;

      p {
        font-size: 24px;
        margin-bottom: 6px;
      }
    }
  }

  .formHead {
    margin-bottom: 10px;
    h2 {
      display: block;
      font-size: ${metrics.smallTitleFontSize};
      font-weight: ${metrics.bold};
    }
    h3 {
      font-size: 15px;
      font-weight: ${metrics.light};
    }
  }

  .formMain {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 60px;
    grid-row-gap: 15px;
    padding: 20px 0;
  }

  .cta {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 70px 0;
  }

  @media (max-width: 480px){
    padding: 0px 2.5%;
    padding-top: 50px;
    .formMain{
      grid-template-columns: 1fr;
    
    }
  }

`;

export const UnformInput = styled(Input)`
  display: flex;
  flex-direction: column;
  border: none;
  border-bottom: 1px solid ${colors.preto};
  width: 100%;
  height: 40px;

  &.multiline {
    height: 130px;
  }
`;


export const Donwloads = styled.div`
  margin: 0 auto;
  max-width: 1280px;
  width: 95%;
  padding-top: 120px;
  h1 {
    &:before {
      width: 125%;
      left: 0;
    }
  }

  .grid {
    padding: 0 5%;
    padding-top: 120px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);

    .block {
      &:nth-of-type(2) {
        border-left: 1px solid ${colors.preto};
        border-right: 1px solid ${colors.preto};
      }
      display: flex;
      flex-direction: column;
      align-items: center;

      img {
        height: 90px;
      }

      h2 {
        font-size: ${metrics.smallTitleFontSize};
        margin: 20px 0;
        text-align: center;
      }

      ul {
        display: block;
        text-align: left;
        li {
          list-style: none;
          margin-bottom: 15px;
          a {
            transition: 0.5s ease;
            color: ${colors.preto};

            &:hover {
              color: ${colors.bordo};
              text-decoration: none;
            }
          }
        }
      }
    }
  }

  @media (max-width: 480px) {
    h1 {
      padding-left: 20px;
      &:before {
        /* width: 100%; */
      }
    }

    .grid {
      grid-template-columns: 1fr;
      padding-top: 50px;
      .block {
        padding: 50px 0;
        border: none;
        border-bottom: 1px solid ${colors.preto};
        &:nth-of-type(2) {
          border-left: none;
          border-right: none;
        }
        &:last-of-type{
          border-bottom: none;
        }
      }
    }
  }
`;
