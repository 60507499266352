import styled from "styled-components";
import colors from "../../styles/colors";
import metrics from "../../styles/metrics";

export const FooterUpperWrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 90%;
  margin: ${metrics.sectionMargin} auto;
  max-width: 1280px;
  h3 {
    text-align: center;
    text-transform: inherit;
    font-size: ${metrics.smallTitleFontSize};
    position: relative;
    z-index: 1;
    display: inline-block;
    margin-bottom: 100px;
    /* &:after {
      content: "";
      z-index: -1;
      width: 200px;
      height: 20px;
      top: 15px;
      position: absolute;
      background-color: ${colors.bordo};
      right: 0;
    } */
  }

  .icons {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    .item {
      padding: 0 60px;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      margin-bottom: 20px;
      img {
        align-self: center;
        height: 160px;
        margin-bottom: 50px;
      }

      h6 {
        font-size: ${metrics.smallTitleFontSize};
        font-weight: ${metrics.bold};
        display: flex;
        align-items: center;
        text-transform: inherit;
        height: 150px;
      }
      p {
        font-size: ${metrics.textFontSize};
      }
    }
  }

  @media (max-width: 480px) {
    width: 95%;
    max-width: 100%;
    margin: 50px auto;
    h3 {
      margin-bottom: 50px;
      &:after {
        width: 40%;
        height: 20px;
        top: 10px;
        top: 3px;
        right: -10%;
      }
    }
    .icons {
      grid-template-columns: 1fr;
      margin-bottom: 30px;
      .item {
        padding: 0;
        h6 {
          height: 80px;
          padding: 0 5%;
        }
        img {
          height: 100px;
          margin-bottom: 0;
        }
      }
    }
  }
`;

export const FooterLowerWrapper = styled.section`
  background-color: ${colors.preto};
  width: 100%;
  height: 530px;
  display: grid;
  padding-top: 80px;
  grid-template-columns: 1fr 0.9fr 1fr;

  .menuWrapper {
    display: grid;
    grid-template-columns: 0.8fr 1fr;
    align-items: center;
    .socials {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      svg {
        color: white;
        transition: 0.5s ease;
        cursor: pointer;
        &:hover {
          color: ${colors.bordo};
        }

        &:nth-child(1) {
          margin-bottom: 40px;
        }
      }
    }

    ul {
      position: relative;
      height: 250px;
      padding-top: 5px;

      /* &:before {
        content: "Mapa do site";
        font-size: ${metrics.bigTextFontSize};
        color: ${colors.bordo};
        position: absolute;
        transform: translate(-50%, -50%) rotate(-90deg);
        text-transform: uppercase;
        font-weight: ${metrics.bold};
        top: 50%;
        left: -10%;
      } */
      li {
        list-style-type: none;

        a {
          line-height: 40px;
          font-size: 25px;
          font-weight: ${metrics.bold};
          text-transform: inherit;
          color: white;
          text-decoration: none;
          position: relative;
          z-index: 1;
          &:before {
            position: absolute;
            z-index: -1;
            content: "";
            width: 0;
            height: 20px;
            left: 0;
            top: 10px;
            background-color: ${colors.bordo};
            transition: 0.5s ease;
          }
          &:hover {
            &:before {
              width: 100%;
            }
          }
        }
      }
    }
  }

  .logoWrapper {
    display: flex;
    align-items: flex-end;
    
    .block {
      background-color: ${colors.preto};
      height: 400px;
      width: 100%;
      padding: 60px 80px 0 0;
      display: flex;
      justify-content: center;
      img {
        width: auto;
        height: 95px;
      }
    }
  }

  .infoWrapper {
    display: flex;
    flex-direction: column;
    padding: 0 60px;
    /* align-items: center; */
    justify-content: center;
    text-align: left;
    span {
      line-height: 40px;
      color: white;
      font-size: 25px;
      font-weight: ${metrics.bold};
      margin-bottom: 20px;
    }

    a {
      text-decoration: none;

      &:first-of-type {
        margin-top: -22px;
      }
    }
  }

  @media (max-width: 480px) {
    grid-template-columns: 1fr;
    padding: 60px 20px;
    height: auto;
    .menuWrapper {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-evenly;
      ul {
        height: auto;
        margin-bottom: 30px;

        &:before{
          left: -15%;
        }
      }

      .socials {
        /* flex-direction: row; */
      }
    }
    .logoWrapper {
      display: none;
    }
    .infoWrapper {
      text-align: center;
      padding: 0;
      span {
        font-size: 15px;
        margin-bottom: 0;
      }
    }
  }
`;

export const Copyright = styled.div`
  background-color: white;
  height: 75px;
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    font-weight: ${metrics.light};
    font-size: 14px;
    z-index: 1;

    a {
      text-decoration: none;
      color: ${colors.bordo};
      position: relative;
      transition: 0.5s ease;
      &:hover {
        color: white;
        &:before {
          width: 110%;
        }
      }
      &:before {
        transition: 0.5s ease;
        content: "";
        position: absolute;
        z-index: -1;
        background-color: ${colors.bordo};
        width: 0;
        top: 2px;
        right: 0;
        height: 12px;
      }
    }
  }
`;
