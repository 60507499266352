import React, { useState } from "react";

import ademail from "../../services/ademail";

import { PageTitle, SubmitButton } from "../../styles/global";
import { Container, Unform, UnformInput } from "./styles";

import * as Yup from "yup";

export default function Contato() {
  const [cliente, setCliente] = useState(false);
  const [formState, setFormState] = useState("Enviar");

  const handleChangeClienteType = (isCliente, e) => {
    if (isCliente) {
      //se selecionou field de já é cliente
      setCliente(true);
    } else {
      //se nao...
      setCliente(false);
    }
  };

  const handleFormSubmit = async data => {
    const isCliente = cliente;
    setFormState("Enviando...");

    const body = `<p>Nome: ${data.Nome}</p>
    <p>${isCliente ? "Já é cliente" : "Ainda não é cliente"}</p>
    <p>Email: ${data.Email}</p>
    <p>Telefone: ${data.Telefone}</p>
    <p>Mensagem: ${data.Mensagem}</p>`;

    const mail = {
      to: "informacoes@gestpar.com.br",
      from: data.Email,
      as: data.Nome,
      subject: `Gestpar - ${data.Assunto}`,
      message: body
    };

    try {
      const response = await ademail.post("/email", mail);
      if (response) {
        setFormState("Enviado!");
      }
    } catch (err) {
      setFormState("Erro!");
    }
  };

  const schema = Yup.object().shape({
    Nome: Yup.string().required("Campo Obrigatório"),
    Email: Yup.string()
      .email("Este não é um e-mail válido")
      .required("Campo Obrigatório"),
    Telefone: Yup.string("Campo Obrigatório"),
    Assunto: Yup.string().required("Campo Obrigatório"),
    Mensagem: Yup.string().required("Campo Obrigatório")
  });
  return (
    <Container>
      <PageTitle>Contato</PageTitle>
      <Unform schema={schema} onSubmit={handleFormSubmit}>
        <div className="precisando">
          <h1>Entre em contato com a gente!</h1>

          <div>
            <p><strong>Endereço: </strong>R. Brasil, 262 - Oficinas</p>
            <p>Ponta Grossa - PR, 84036-010</p>
            <p>(42) 3026-2222</p>
            <p>informacoes@gestpar.com.br</p>
          </div>
        </div>

        <div className="formMain">
          <div className="input">
            <UnformInput label="Nome*" name="Nome" />
          </div>
          <div className="input">
            <UnformInput label="Email*" name="Email" />
          </div>
          <div className="input">
            <UnformInput label="Telefone" name="Telefone" />
          </div>
          <div className="input">
            <UnformInput label="Assunto*" name="Assunto" />
          </div>
          <div className="clienteOptions">
            <div
              className={`clienteOption ${cliente ? "" : "active"}`}
              onClick={e => handleChangeClienteType(false, e)}
            >
              <input
                type="radio"
                name="cliente"
                value="Não"
                checked={cliente === false}
                onChange={e => handleChangeClienteType(false, e)}
              />
              <div>
                <strong>Ainda não sou cliente</strong>
                <span>Gostaria de tirar dúvidas ou obter informações?</span>
              </div>
            </div>
            <div
              className={`clienteOption ${cliente ? "active" : ""}`}
              onClick={e => handleChangeClienteType(true, e)}
            >
              <input
                type="radio"
                name="cliente"
                value="Sim"
                checked={cliente === true}
                onChange={e => handleChangeClienteType(false, e)}
              />
              <div>
                <strong>Já sou cliente</strong>
                <span>
                  Gostaria de solicitar suprimentos ou reportar um problema?
                </span>
              </div>
            </div>
          </div>
          <div className="input">
            <UnformInput
              className="multiline"
              multiline
              label="Mensagem*"
              name="Mensagem"
            />
          </div>
        </div>
        <div className="cta">
          <SubmitButton disabled={`${formState === "Enviado!" ? "true" : ""}`}>
            {formState}
          </SubmitButton>
        </div>
      </Unform>
    </Container>
  );
}
