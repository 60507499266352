import React from "react";
import { Switch, Route } from "react-router-dom";
import ScrollToTop from "react-router-scroll-top";

import Home from "../pages/Home";
import Sobre from "../pages/Sobre";
import Servicos from "../pages/Servicos";
import Produtos from "../pages/Produtos";
import Contato from "../pages/Contato";
import Suporte from "../pages/Suporte";
import Downloads from "../pages/Downloads";
import Produto from "../pages/SingleProduto";
import Blog from "../pages/Blog";
import Post from "../pages/SingleBlog";

const Routes = () => (
  <Switch>
    <ScrollToTop>
      <Route exact path="/" component={Home} />
      <Route path="/sobre" component={Sobre} />
      <Route path="/servicos" component={Servicos} />
      <Route path="/equipamentos" component={Produtos} />
      <Route path="/contato" component={Contato} />
      <Route path="/suporte" component={Suporte} />
      <Route path="/downloads" component={Downloads} />
      <Route path="/equipamento/:slug" component={Produto} />
      <Route exact path="/blog" component={Blog} />
      <Route path="/blog/:slug" component={Post} />
    </ScrollToTop>
  </Switch>
);

export default Routes;
