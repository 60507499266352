import React, { useState, useEffect } from "react";

// Importa o serviço da API
import api from "../../services/api";

import { PageTitle } from "../../styles/global";
import { Container } from "./styles";

import search from "../../assets/icons/search.svg";
import support from "../../assets/icons/support.svg";
import website from "../../assets/icons/website.svg";

export default function Suporte() {
  
  // Variáveis que vai receber minhas informações
  const [donwloads, setDonwloads] = useState([]);          // Estado inicial recebe um array vazio
  const [loading, setLoading] = useState(true);      // Seta o estado inicial do loading

  // Declara o useEffect para setar o loading
  useEffect(() => {
     setLoading(true);
  }, []);

  // Declara outro useEffect para os Donwloads
  useEffect(() => {
     // Faz a requisição e já atribui o retorno dela ao estado dos Donwloads
     async function getDonwloads() {
        // Requisição para API
        const { data } = await api.get('api/downloads');
        
        // Já chama a função para setar os dados no estado
        setDonwloads(data[0]);

        console.log( data[0] );
     };

     // Já chama a função para ficar dentro do useEffect
     getDonwloads();
  }, []);

  return (
    <Container>
      <PageTitle>Downloads</PageTitle>
      <div className="grid">
        <div className="block">
          <img src={support} alt="Suporte" />
          <h2>
            Suporte<br/>Remoto
          </h2>
          <ul>
            {!!donwloads.suporte_remoto
            ? donwloads.suporte_remoto.map( (down, index) => (
              <li key={index}>
                <a href={down.link}>{down.titulo}</a>
              </li>
              ))
            : ""}
          </ul>
        </div>
        <div className="block">
          <img src={search} alt="Suporte" />
          <h2>Coleta de<br/>Contadores</h2>
          <ul>
            {!!donwloads.coleta_de_contadores
            ? donwloads.coleta_de_contadores.map( (down, index) => (
              <li key={index}>
                <a href={down.link}>{down.titulo}</a>
              </li>
              ))
            : ""}
          </ul>
        </div>
        <div className="block">
          <img src={website} alt="Suporte" />
          <h2>Softwares de<br/>Monitoramento</h2>
          <ul>
            {!!donwloads.softwares_de_monitoramento
            ? donwloads.softwares_de_monitoramento.map( (down, index) => (
              <li key={index}>
                <a href={down.link}>{down.titulo}</a>
              </li>
              ))
            : ""}
          </ul>
        </div>
      </div>
    </Container>
  );
}
