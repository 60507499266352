import styled from "styled-components";
import colors from "../../styles/colors";
import metrics from "../../styles/metrics";

import img_sobre1 from "../../assets/img/img_sobre1.jpg";

export const Main = styled.section`
  /* height: 100vh; */
  display: grid;
  grid-template-columns: 1fr 0.8fr;

  .imgWrapper {
    height: 100vh;
    width: 100%;
    background-size: cover;
    background-position: center;
    background-image: url(${img_sobre1});
  }

  .mainWrapper {
    padding: 100px 10%;
    img{
      height: 95px;
      
      @media (max-width: 480px) {
        width: 80%;
        height: auto;
        display: block;
        margin: 20px auto;
      }
    }
    h1 {
      font-size: ${metrics.bigTitleFontSize};
      color: white;
      z-index: 2;
      position: absolute;
      padding: 0 30px;
      top: 250px;
      &:before {
        top: -15px;
        background-color: ${colors.preto};
        content: "";
        position: absolute;
        z-index: -1;
        left: -30px;
        height: 140px;
        width: calc(100% + 60px);

        @media (max-width: 480px) {
          width: calc(100% + 30px) !important;
          height: 80px;
        }
      }
    }
    .sobre {
      margin-top: 220px;

      @media (max-width: 480px) {
        margin-top: 170px;
      }
    }
    p {
      line-height: 1.5;
      font-size: 15px;
      margin-bottom: 30px;
    }

    .cta {
      display: grid;
      grid-template-columns: 1fr 1fr;
      justify-content: center;
      align-items: center;
      grid-column-gap: 30px;
    }
  }

  @media (max-width: 480px) {
    display: block;
    height: auto;
    .imgWrapper {
      display: none;
    }
    .mainWrapper {
      padding: 100px 5% 30px 5%;

      h1{
        &:before{
          width: 100%;
        }
      }
      .cta {
        grid-template-columns: 1fr;
        grid-row-gap: 30px;
        a {
          width: 100%;
          font-size: 16px;
        }
      }
    }
  }
`;

export const Galeria = styled.section`
  margin: 20px auto 120px auto;
  max-width: 1280px;
  width: 90%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 2px;
  grid-row-gap: 2px;

  .foto {
    background-size: cover;
    background-position: center;
    height: 300px;
    width: 100%;
    position: relative;
    &:hover {
      &:after {
        opacity: 0.7;
      }
    }
    &:after {
      height: 300px;
      width: 100%;
      background-color: ${colors.preto};
      opacity: 0;
      transition: 0.5s ease;
      position: absolute;
      content: "";
    }
  }

  @media (max-width: 480px) {
    margin: 10px auto;
    grid-template-columns: 1fr 1fr;

    .foto{
      height: 100px;
    }
  }
`;
