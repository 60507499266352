import styled from "styled-components";

export const Topo = styled.div `
  position: relative;
  height: 550px;
  background-size: cover;
  background-position: center;

  .content {
    max-width: 1100px;
    width: 85%;
    margin: auto;
    height: 600px;
    display: flex;
    align-items: flex-end;

    h1 {
      background: #414141;
      color: #fff;
      padding: 15px 0 30px 35px;
      height: auto;
      width: auto;
      margin-bottom: 70px;
      font-size: 70px;
      line-height: 70px;
      box-sizing: border-box;
      display: block;
      padding-right: 30px;
    }
  }

  @media (max-width: 480px){
    .content {
      h1 {
        width: auto;
        font-size: 44px;
        line-height: 44px;
      }
    }
  }
`;

export const Container = styled.div `
  margin: 0 auto;
  max-width: 1100px;
  width: 85%;
  
  .BlogWrapper {
    padding-top: 50px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 90px 15px;

    .item {
      > a {
        text-decoration: none;
        color: #000;
        text-align: center;
      }

      img {
        object-fit: cover;
        width: 100%;
        height: 320px;
        display: block;
        opacity: 1;
        transition: all ease-in .3s;
      }

      h2 {
        text-align: center;
        font-size: 24px;
        line-height: 24px;
        margin: 20px 0 10px 0;
        color: #000000;
        height: 50px;
      }

      figure {
        background: #980C3A;
      }

      p {
        font-size: 16px;
        line-height: 22px;
        font-weight: 300;
        height: 110px;
      }

      button {
        margin: 30px auto 0 auto;
        display: block;
        background: none;
        font-weight: bold;
        border: none;
        position: relative;
        border-bottom: 5px solid #980C3A;
        width: 90%;
        font-size: 20px;
        padding-bottom: 7.5px;
        cursor: pointer;
      }

      :hover img {
        opacity: .5;
      }
    }
  }

  @media (max-width: 480px){
    .BlogWrapper {
      grid-template-columns: 1fr;

      .item:last-of-type {
        margin-bottom: 60px;
      }
    }
  }
`;