import styled, { createGlobalStyle } from "styled-components";
import "normalize.css";
import colors from "./colors";
import metrics from "./metrics";

import { Link } from "react-router-dom";

const GlobalStyle = createGlobalStyle`

    *{
        margin: 0;
        padding: 0;
        font-family: 'Asap', sans-serif;
        -webkit-font-smoothing: antialiased;
        box-sizing: border-box !important;   
    }

    body{
      overflow-x: hidden !important;
    }


    h1,h2,h3,h4,h5,h6{
        margin: 0;
    }

    @media(max-width: 480px){
      *{
        /* border: 1px dashed red; */
        }

      #root{
        overflow-x: hidden;
      }
    }

`;

export const LinkButton = styled(Link)`
  cursor: pointer;
  display: flex;
  width: 100%;
  max-width: 330px;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: ${colors.preto};
  height: 40px;
  font-weight: ${metrics.bold};
  text-decoration: none;
  font-size: 20px;
  position: relative;
  transition: 0.6s ease;
  &:hover {
    color: white;
    &:before {
      height: 40px;
      transition: 0.4s ease;
    }
  }
  &:before {
    height: 6px;
    width: 100%;
    background-color: ${colors.bordo};
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -1;
  }
`;

export const SubmitButton = styled.button`
  border: none;
  width: 320px;
  cursor: pointer;
  display: flex;
  max-width: 330px;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: ${colors.preto};
  height: 40px;
  font-weight: ${metrics.bold};
  text-decoration: none;
  font-size: 20px;
  position: relative;
  z-index: 1;
  transition: 0.6s ease;
  &:hover {
    color: white;
    &:before {
      height: 40px;
      transition: 0.4s ease;
    }
  }
  &:before {
    height: 6px;
    width: 100%;
    background-color: ${colors.bordo};
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -1;
  }
`;

export const PageTitle = styled.h1`
  color: white;
  font-size: 80px;
  text-transform: inherit;
  padding-left: 70px;
  position: relative;
  z-index: 1;
  display: inline-block;

  &:before {
    background-color: ${colors.preto};
    width: 95%;
    margin-left: -10%;
    height: 180px;
    content: "";
    position: absolute;
    z-index: -1;
    top: -30px;
  }

  @media (max-width: 480px) {
    font-size: 50px;
    &:before {
      height: 90px;
      top: -10px;
    }
  }
`;

export const LoadingDots = styled.div`
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  .dot {
    position: relative;

    &:before {
      content: "";
      font-size: 120px;
      color: ${props => props.dotsColor};
      animation: animate_loading 1s infinite;
      position: absolute;
    }

    @keyframes animate_loading {
      0% {
        content: ".  ";
      }
      33% {
        content: ".. ";
      }
      100% {
        content: "...";
      }
    }
  }
`;

export const LoadingClip = styled.div`
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50vh;

  &.fullscreen {
    height: 100vh !important;
  }
`;

export default GlobalStyle;
