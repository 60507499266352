import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { ProdutosWrapper, Container } from "./styles";
import { LinkButton, PageTitle, LoadingClip } from "../../styles/global";
import api from "../../services/api";

import clip_loading from "../../assets/icons/loading.gif";

export default function Produtos() {
  const [produtos, setProdutos] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    getProdutos().then(() => {
      setLoading(false);
    });
  }, []);

  const getProdutos = async () => {
    const response = await api.get("wp/v2/produtos");
    if (response) {
      setProdutos(response.data);
    }
  };

  return (
    <Container>
      <ProdutosWrapper>
        <div className="title">
          <PageTitle>Nossos equipamentos</PageTitle>
          <h2>Os melhores equipamentos para atender a sua demanda</h2>
        </div>
        {!!loading ? (
          <LoadingClip>
            <img src={clip_loading} alt="" />
          </LoadingClip>
        ) : (
          <div className="listaProdutos">
            {!!produtos
              ? produtos.map(produto => (
                  <a href={`/equipamento/${produto.slug}`} key={produto.id}>
                    <div className="produto">
                      <img
                        src={produto.acf.foto_principal.sizes.large}
                        alt={produto.title.rendered}
                      />
                      <h6>{produto.title.rendered}</h6>
                    </div>
                  </a>
                ))
              : ""}
          </div>
        )}
      </ProdutosWrapper>
      <div className="cta">
        <LinkButton to="/servicos">Conheça nossos Serviços</LinkButton>
      </div>
    </Container>
  );
}
