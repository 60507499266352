import React, { useState, useEffect } from "react";
import ReactHtmlParser from 'react-html-parser';

import api from "../../services/api";

import { ProdutoWrapper, Container, DescricaoWrapper } from "./styles";
import {
  LinkButton,
  PageTitle,
  LoadingDots,
  LoadingClip
} from "../../styles/global";

import clip_loading from "../../assets/icons/loading.gif";

export default function Produtos(props) {
  const [produto, setProduto] = useState(null);
  const [loading, setLoading] = useState(true);

  const getProduto = async () => {
    const slug = props.match.params.slug;
    const response = await api.get(`wp/v2/produtos?slug=${slug}`);
    if (response.data) {
      console.log(response.data[0]);
      setProduto(response.data[0]);
      document.title = `Gestpar - ${response.data[0].title.rendered}`;
    }
  };

  useEffect(() => {
    setLoading(true);
    if (props.match.params.slug) {
      getProduto().then(() => {
        setLoading(false);
      });
    }
  }, []);

  return (
    <Container>
      <ProdutoWrapper>
        <div className="title">
          <PageTitle>Nossos equipamentos</PageTitle>
          <h2>Os melhores equipamentos para atender a sua demanda</h2>
        </div>
        {!!produto ? (
          <>
            <div className="mainWrapper">
              <div className="fotosWrapper">
                <a href={produto.acf.foto_principal.url} data-fancybox="gallery">
                  <div
                    className="fotoPrincipal"
                    style={{
                      backgroundImage: `url(${
                        produto.acf.foto_principal.sizes.medium
                      })`
                    }}
                  />
                </a>

                <div className="gallery">
                  {produto.acf.fotos.map(foto => (
                    <a href={foto.url} key={foto.id} data-fancybox="gallery">
                      <div
                        className="item"
                        style={{ backgroundImage: `url(${foto.sizes.large})` }}
                      />
                    </a>
                  ))}
                </div>
              </div>

              <div className="infoWrapper">
                <h1>{ReactHtmlParser(produto.title.rendered)}</h1>
                {ReactHtmlParser(produto.acf.descricao)}
              </div>
            </div>
          </>
        ) : (
          <LoadingClip>
            <img src={clip_loading} alt="Carregando..." />
          </LoadingClip>
        )}
      </ProdutoWrapper>
      {!!produto && produto.acf.descricao ? (
        <DescricaoWrapper>
          <h2>Descrição</h2>
          <div className="content">
            {ReactHtmlParser(produto.acf.informacoes)}
          </div>
        </DescricaoWrapper>
      ) : (
        ""
      )}

      <div className="cta">
        <LinkButton to="/equipamentos">Ver outros equipamentos</LinkButton>
        <LinkButton to="/servicos">Conheça nossos serviços</LinkButton>
      </div>
    </Container>
  );
}
