// Importa Libs do React
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ReactHtmlParser from 'react-html-parser';

// Importa o loading do início
import clip_loading from "../../assets/icons/loading.gif";

// Importa os CSS
import { Topo, ProdutosWrapper, Container } from "./styles";
import { LinkButton, PageTitle, LoadingClip } from "../../styles/global";

// Importa o serviço da API
import api from "../../services/api";

// Importa as imagens
import img_topo from "../../assets/img/blog_topo.jpg";

// Inicia a pagina do blog
export default function Blog(){

   // Variáveis que vai receber minhas informações
   const [posts, setPosts] = useState([]);          // Estado inicial recebe um array vazio
   const [loading, setLoading] = useState(true);      // Seta o estado inicial do loading

   // Declara o useEffect para setar o loading
   useEffect(() => {
      setLoading(true);
   }, []);

   // Declara outro useEffect para os posts
   useEffect(() => {
      // Faz a requisição e já atribui o retorno dela ao estado dos posts
      async function getPosts() {
         // Requisição para API
         const { data } = await api.get('wp/v2/blog');

         // Já chama a função para setar os dados no estado
         setPosts(data.blog);
      };

      // Já chama a função para ficar dentro do useEffect
      getPosts();
   }, []);

   /**
    * Retorna o JSX pra montar a tela
    */
   return ( <>
      <Topo style={{ backgroundImage: `url(${img_topo})` }}>
         <div className="content">
            <h1>Confira nossas novidades</h1>
         </div>
      </Topo>
      
      <Container> 
         <div className="BlogWrapper items">
            {posts.map(post => (
               <div className="item">
                  <Link key={post.id} to={`blog/${post.slug}`}>
                     <figure>
                        <img src={post.imagem.sizes.thumbnail} alt={post.titulo} />
                     </figure>
                  </Link>

                  <Link key={post.id} to={`blog/${post.slug}`}>
                     <h2>{post.titulo}</h2>
                  </Link>

                  <Link key={post.id} to={`blog/${post.slug}`}>
                     <p>
                        {ReactHtmlParser(post.resumo)}
                     </p>
                  </Link>
                  
                  <Link key={post.id} to={`blog/${post.slug}`}>
                     <button>Continuar lendo</button>
                  </Link>
               </div>
            ))}
         </div>
      </Container>
   </>);
}
